import React, { FunctionComponent, useState } from "react";
import cx from "classnames";
import { FaMask, FaGlobeAfrica } from "react-icons/fa";
import { FiImage, FiVideo, FiRefreshCcw, FiSmartphone } from "react-icons/fi";

import { ArtPiece } from "@shared/models/ArtPiece";

import { AssetMap } from "./AssetMap";

import { faceTrackingPath, worldTrackingPreviewPath } from "~/lib/routes";

import "./ModelPreview.css";

interface ModelViewerAttributes {
  src: string;
  alt: string;
  ar?: boolean;
  "auto-rotate"?: boolean;
  "camera-controls"?: boolean;
  class?: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & ModelViewerAttributes,
        HTMLElement
      >;
    }
  }
}

export interface ModelPreviewProps {
  artPiece: ArtPiece;
  showButtons?: boolean;
  //eslint-disable-next-line react/no-unused-prop-types
  contractAddress?: string;
  className?: string;
}

export type ModelPreviewMode = "model-viewer" | "poster" | "video" | "map";

export const ModelPreview: FunctionComponent<ModelPreviewProps> = ({
  artPiece,
  showButtons,
  className,
}) => {
  const [mode, setMode] = useState(startingMode(artPiece));

  const showCoordinatesOnMap =
    artPiece.showOnMap &&
    artPiece.coordinates?.latitude &&
    artPiece.coordinates?.longitude;

  return (
    <>
      <div className={cx("model-preview", className)}>
        <ModelViewer mode={mode} artPiece={artPiece} />
      </div>

      {showButtons && (
        <div className="model-preview__buttons">
          {hasGlb(artPiece) && (
            <button
              type="button"
              className={
                mode === "model-viewer"
                  ? "model-preview__button model-preview__button--active"
                  : "model-preview__button"
              }
              onClick={() => setMode("model-viewer")}
            >
              <FiRefreshCcw
                className="model-preview__button-icon"
                title="3D Model"
              />
              3D
            </button>
          )}

          {artPiece.poster && (
            <button
              type="button"
              className={
                mode === "poster"
                  ? "model-preview__button model-preview__button--active"
                  : "model-preview__button"
              }
              onClick={() => setMode("poster")}
            >
              <FiImage className="model-preview__button-icon" title="Poster" />
              poster
            </button>
          )}

          {artPiece.mp4 && (
            <button
              type="button"
              className="model-preview__button"
              onClick={() => setMode("video")}
            >
              <FiVideo
                className="model-preview__button-icon"
                title="Video Preview"
              />
              video
            </button>
          )}
          {showCoordinatesOnMap && (
            <button
              type="button"
              className={
                mode === "map"
                  ? "model-preview__button model-preview__button--active"
                  : "model-preview__button"
              }
              onClick={() => setMode("map")}
            >
              <FaGlobeAfrica
                className="model-preview__button-icon"
                title="World Location"
              />
              map
            </button>
          )}

          {artPiece.faceTracking?.enabled && (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a
              className="model-preview__button"
              href={faceTrackingPath(artPiece)}
              target="_blank"
            >
              <FaMask
                className="model-preview__button-icon"
                title="Try on mask"
              />
              Try on
            </a>
          )}

          {artPiece.modelPath && (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a
              className="model-preview__button"
              href={worldTrackingPreviewPath(artPiece)}
              target="_blank"
            >
              <FiSmartphone
                className="model-preview__button-icon"
                title="Visit"
              />
              AR
            </a>
          )}
        </div>
      )}
    </>
  );
};

export interface ModelViewerProps {
  mode: ModelPreviewMode;
  artPiece: ArtPiece;
  //eslint-disable-next-line react/no-unused-prop-types
  className?: string;
}
export const ModelViewer: FunctionComponent<ModelViewerProps> = ({
  mode,
  artPiece,
}) => {
  if (mode === "poster") {
    return (
      <div className="model-preview__image-wrapper">
        <img
          className="model-preview__image"
          src={artPiece.poster}
          alt={artPiece.name}
        />
      </div>
    );
  }

  if (mode === "video") {
    return (
      <video autoPlay loop muted className="model-preview__video">
        <source src={artPiece.mp4} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    );
  }
  if (mode === "map") {
    return <AssetMap artPiece={artPiece} />;
  }

  return (
    <div className="model-preview__glb-wrapper">
      <model-viewer
        class="model-preview__glb"
        src={artPiece.modelPath}
        ios-src={artPiece.iosPath}
        alt={artPiece.name}
        disable-zoom
        auto-rotate
        camera-controls
        background-color="#455A64"
      />
    </div>
  );
};

const startingMode = (artPiece: ArtPiece): ModelPreviewMode => {
  const canShowModelViewer = hasGlb(artPiece);

  if (artPiece.defaultPreview === "model" && canShowModelViewer) {
    return "model-viewer";
  }
  if (artPiece.defaultPreview === "video" && artPiece.mp4) {
    return "video";
  }
  if (artPiece.defaultPreview === "poster" && artPiece.poster) {
    return "poster";
  }
  if (artPiece.defaultPreview === "map" && artPiece.showOnMap) {
    return "map";
  }

  if (hasGlb(artPiece)) return "model-viewer";
  if (artPiece.mp4) return "video";
  if (artPiece.poster) return "poster";
  if (artPiece.showOnMap) return "map";
  return null;
};

const hasGlb = (artPiece: ArtPiece): boolean => {
  return artPiece.modelPath && artPiece.modelPath !== "None listed";
};
